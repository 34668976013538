import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        // display={'flex'}
        // justifyContent={'space-between'}
        // alignItems={{ xs: 'flex-start', sm: 'center' }}
        // flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
          Cancellation Policy

          </Typography>
          <Typography variant={'h6'}></Typography>
        </Box>
      </Box>
      <Divider sx={{ marginY: 6 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={18} md={12}>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              
            </Typography>
            <Typography component={'p'} align='justify'>
            Cancellation of Admission / Refund Policy of Fees National Public School, Yeshwanthpur reserves the right to cancel the admission / registration of the student during any period of the academic session. Once the student admission process is completed, no refund of fees to the student would be entertained.

            </Typography>
          </Box>
          <br/>
          <Box marginBottom={3}>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            Cancellation by the Parent

            </Typography>
            <Typography component={'p'} align='justify'>
            If the parent chooses to withdraw the admission of the student from the school for any reason whatsoever, no refund of amount paid would be given back.
            <br/><br/>
            If for any reason, the parent / student has paid in excess of the required amount at any time during the academic session either by cheque, draft, credit/debit card, Electronic Fund Transfer or cash, the excess fee amount collected would be adjusted against the ensuing term payments.
            </Typography>
            <br/>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            Cancellation by National Public School, Yeshwanthpur

            </Typography>
            <Typography component={'p'} align='justify'>
            If circumstances cause National Public School, Yeshwanthpur to cancel the admission, the school will inform the student / parent concerned regarding the reasons for cancellation. In the event of the case for cancellation of admission being found genuine and legitimate, the school management will refund the fees (amount to be considered on a case to case basis) to the / student by means of a crossed cheque in favour of the name of the parent.
            </Typography>

          </Box>
          <Box>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            NPS Terms
            </Typography>
            <Typography component={'p'} align = 'justify'>
            This website is owned and operated by the National Public School, Yeshwanthpur Administration and Staff. The website has been structured to provide information related to school and school activities and directly reflect all policies and procedures set forth by the Management. National Public School, Yeshwanthpur has the right, at any time, to change or discontinue any feature or aspect of this website, for any reason; that is, without limitation, availability or equipment needed for accessing and utilizing this website.
            </Typography>
            <br />
            
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
